import React from "react";

import { Props } from "../../types/Breadcrumb";
import MyLink from "../MyLink";

/**
 * パンクズ
 */
export const Presenter: React.VFC<Props> = ({ labels }) => {
  return (
    <div className="l-container py-4">
      <ul className="flex flex-nowrap overflow-auto text-xs">
        <li className="text-primary">
          <MyLink to="/">HOME</MyLink>
        </li>
        {labels.map((label, index) =>
          index < labels.length - 1 ? (
            <li
              key={index}
              className="relative whitespace-nowrap pl-9 text-primary before:absolute before:top-[6px] before:left-[14px] before:h-1 before:w-1 before:rotate-45
                before:border-t before:border-r before:border-solid before:border-primary before:content-['']"
            >
              {label.url ? (
                <MyLink to={label.url}>{label.text}</MyLink>
              ) : (
                label.text
              )}
            </li>
          ) : (
            //最下層ページにはリンクなし
            <li
              key={index}
              className="relative whitespace-nowrap pl-9 before:absolute before:top-[6px] before:left-[14px] before:h-1 before:w-1 before:rotate-45
         before:border-t before:border-r before:border-solid before:border-primary before:content-['']"
            >
              {label.text}
            </li>
          )
        )}
      </ul>
    </div>
  );
};
