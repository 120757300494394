import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Button01 } from "../components/Button01";
import { Heading01 } from "../components/Heading01";
import { Layout } from "../components/Layout/index";
import bgimgPc from "../images/h1/h1contact.png";
import bgimgSp from "../images/h1/h1contact_sp.png";

const ThanksPage: React.VFC = () => {
  return (
    <Layout
      title="お問い合わせ完了"
      description="ミスギのお問い合わせ完了ページです。"
    >
      <Heading01
        label="CONTACT"
        smallLabel="お問い合わせ完了"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "お問い合わせ" }]} />
      <div className="l-container-sm pt-8 pb-[100vh] pc:pt-[72px]">
        <ul className="mx-auto flex w-[250px] justify-between pc:w-[420px]">
          <li className={`flex-shrink-0 px-6 pc:px-12`}>
            <span
              className={`relative flex h-[30px] w-[30px] items-center justify-center rounded-full border border-solid
           border-grey text-[14px] font-medium text-grey after:absolute after:top-1/2 
           after:right-[-24px] after:h-1.5 after:w-1.5 after:-translate-y-1/2 after:rotate-[45deg] after:border-t
            after:border-r after:border-solid after:border-primary
          after:duration-300 after:content-[''] pc:h-10 pc:w-10 pc:text-[20px] pc:after:right-[-48px] pc:after:h-3 pc:after:w-3
          `}
            >
              1
            </span>
            <p
              className={`mt-3 text-center text-base text-grey pc:text-[20px]`}
            >
              入力
            </p>
          </li>
          <li className={`flex-shrink-0 px-6 pc:px-12`}>
            <span
              className={`relative flex h-[30px] w-[30px] items-center justify-center rounded-full border border-solid
           border-grey text-[14px] font-medium text-grey after:absolute after:top-1/2 
           after:right-[-24px] after:h-1.5 after:w-1.5 after:-translate-y-1/2 after:rotate-[45deg] after:border-t
            after:border-r after:border-solid after:border-primary
          after:duration-300 after:content-[''] pc:h-10 pc:w-10 pc:text-[20px] pc:after:right-[-48px] pc:after:h-3 pc:after:w-3
           `}
            >
              2
            </span>
            <p
              className={`mt-3 text-center text-base text-grey pc:text-[20px]`}
            >
              確認
            </p>
          </li>
          <li className={`flex-shrink-0 px-6 pc:px-12`}>
            <span
              className={`relative flex h-[30px] w-[30px] items-center justify-center rounded-full border border-solid
           border-primary bg-primary text-[14px] font-medium text-white pc:h-10 pc:w-10 pc:text-[20px]
           `}
            >
              3
            </span>
            <p
              className={`mt-3 text-center text-base font-medium text-primary pc:text-[20px]`}
            >
              完了
            </p>
          </li>
        </ul>

        <div className="mt-14 w-full rounded-2xl bg-secondary p-10 pc:px-4">
          <h2 className="text-center text-xl font-bold text-primary pc:text-2xl">
            お問い合わせ内容の送信が完了しました。
          </h2>
          <p className="mt-4 text-center text-base pc:mt-6">
            お問い合わせありがとうございます！
            <br />
            内容を確認していただいた上で担当者の方からご連絡させていただきます。
          </p>
        </div>
        <div className="mt-8 flex items-center justify-center">
          <Button01 label="トップへ戻る" link="/" isPageTransition isWhite />
        </div>
      </div>
    </Layout>
  );
};

export default ThanksPage;
