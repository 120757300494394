import React from "react";

import { Props } from "../../types/Heading01";

import imgPc from "../../images/h1/h1product.png";
import imgSp from "../../images/h1/h1product_sp.png";

/**
 * 見出し1、h1をメインに想定
 * 大文字のフォントサイズのみクラス追加で変更可能に
 */
export const Presenter: React.VFC<Props> = ({
  label,
  TagName = "h1",
  smallLabel,
  bgimg = [imgPc, imgSp],
  className = "text-[32px] pc:text-[80px]",
}) => {
  return (
    <div className="relative z-[-1] pt-[68px] pb-[86px] pc:bg-bggrey">
      <div className="l-container">
        <TagName
          className={`font-semibold text-white pc:text-dark ${className}`}
        >
          {label}
        </TagName>
        <span className="mt-2 block text-[12px] leading-none tracking-[.12em] text-white pc:text-[20px] pc:text-dark">
          {smallLabel}
        </span>
        {/* 画像エリアpc */}
        <div
          style={{ backgroundImage: `url('${bgimg[0]}')` }}
          className={`absolute top-0 right-0 hidden h-full w-[70%] max-w-[1000px] bg-cover bg-no-repeat pc:block`}
        ></div>
        {/* 画像エリアsp */}
        <div
          style={{ backgroundImage: `url('${bgimg[1]}')` }}
          className={`absolute top-0 right-0 -z-10 block h-full w-full bg-cover bg-no-repeat pc:hidden`}
        ></div>
      </div>
    </div>
  );
};
